import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import ContentBanner from "../../../../V2/Sections/Banners/Content"

import { getUrlFromStoryblokLink } from "@utils/storyblok"

const StoryblokContentBanner = ({
  blok,
}: Storyblok.BlokProps<Storyblok.ContentBanner>) => {
  return (
    <ContentBanner
      header={blok.text}
      superscript={blok.superscript}
      description={blok.description}
      badgeLabel={blok.badgeLabel}
      reverseOrder={blok.reverseOrder}
      size={blok.size}
      cardBackgroundColor={blok.cardColor}
      secondaryBackgroundColor={blok.secondaryBackgroundColor}
      buttonLink={getUrlFromStoryblokLink(blok.primaryCTALink)}
      buttonText={blok.primaryCTAText}
      buttonBorderColor={blok.primaryCTABorderColor}
      buttonStyle={blok.primaryCTAStyle}
      imageAlt={blok.image.alt}
      imageUrl={blok.image.filename}
      showLogo={blok.showLogo}
      headerLabel={blok.headerLabel}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokContentBanner
