import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../../elements/V2/Typography"

import YocoLogoIcon from "@components/elements/V2/Icons/YocoLogoIcon"
import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import {
  getAccentColor,
  getBackgroundColorClass,
  getFillColorClass,
  getGradientFromColorClass,
  getGradientToColorClass,
} from "@utils/V2/color"

type Props = {
  cardBackgroundColor: Color
  secondaryBackgroundColor?: Color
  header: string
  superscript?: string
  description?: string
  badgeLabel?: string
  reverseOrder?: boolean
  size?: "small" | "large"
  buttonText: string
  buttonLink: string
  buttonBorderColor: Color
  buttonStyle?: "solid" | "bordered"
  imageUrl: string
  imageAlt: string
  showLogo?: boolean
  headerLabel?: string
}

const ContentBanner = ({
  cardBackgroundColor,
  secondaryBackgroundColor,
  header,
  superscript,
  description,
  badgeLabel,
  reverseOrder = false,
  size = "small",
  buttonText,
  buttonLink,
  buttonBorderColor,
  buttonStyle = "bordered",
  imageUrl,
  imageAlt,
  showLogo = false,
  headerLabel,
  ...props
}: Props) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)

  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        "rounded-lg col-span-4 grid grid-cols-1 overflow-hidden sm:col-span-4 sm:grid-cols-2 md:col-span-6 lg:col-span-12",
        !secondaryBackgroundColor
          ? getBackgroundColorClass(cardBackgroundColor)
          : [
              "bg-gradient-to-br",
              getGradientFromColorClass(cardBackgroundColor),
              getGradientToColorClass(secondaryBackgroundColor),
            ]
      )}
      {...props}
    >
      <div
        className={classNames(
          "flex flex-col px-20 py-24 sm:py-32 md:px-32 md:py-48",
          (showLogo || headerLabel) && size === "large"
            ? "sm:gap-32"
            : "sm:gap-16",
          size === "large" ? "justify-center gap-32" : "gap-20 md:gap-32"
        )}
      >
        {(showLogo || headerLabel) && (
          <div className="flex items-center justify-start gap-12">
            {showLogo && (
              <YocoLogoIcon
                height="32"
                className={classNames(
                  "w-auto items-start",
                  getFillColorClass(Color.Blue)
                )}
              />
            )}
            {headerLabel && (
              <Typography
                text={headerLabel}
                size="body-lg"
                font="grotesk"
                weight="medium"
                color={getAccentColor(cardBackgroundColor)}
                className="mt-[2px]"
              />
            )}
          </div>
        )}
        <div className="flex flex-col gap-4">
          {superscript && (
            <Typography
              text={superscript}
              font="grotesk"
              weight="book"
              size="subscript-lg"
              color={getAccentColor(cardBackgroundColor)}
              className="uppercase"
            />
          )}
          <Typography
            text={header}
            font="grotesk"
            weight={size === "large" ? "medium" : "book"}
            size={size === "large" ? "h5" : "lead-lg"}
            color={getAccentColor(cardBackgroundColor)}
          />
          {description && (
            <Typography
              text={description}
              font="grotesk"
              weight="book"
              size="body-md"
              color={getAccentColor(cardBackgroundColor)}
            />
          )}
        </div>
        <PillButton
          size="large"
          style={buttonStyle}
          text={buttonText}
          color={buttonBorderColor}
          linkUrl={buttonLink}
          overrideTextColor={getAccentColor(cardBackgroundColor)}
        />
      </div>
      <div
        className={classNames("relative flex sm:justify-center", {
          "row-start-1 sm:col-start-2": reverseOrder,
        })}
      >
        <div
          className={classNames("flex w-full items-center justify-center", {
            "aspect-square w-full md:absolute md:left-0 md:top-1/2 md:-translate-y-1/2":
              size === "small",
          })}
        >
          <GatsbyStoryblokImage
            image={imageUrl}
            alt={imageAlt || "Banner image"}
            className={classNames(
              size === "large"
                ? "max-h-[250px] !max-w-[250px] sm:max-h-[430px] sm:!max-w-[430px]"
                : "h-full w-full",
              {
                "my-16 sm:m-0": reverseOrder && size === "large",
              },
              { "mr-56": badgeLabel && size === "large" }
            )}
          />
          {badgeLabel && (
            <div className="absolute right-32 flex translate-y-1/2 items-center sm:right-8 md:right-16 lg:right-[30px]">
              <Typography
                font="grotesk"
                weight="book"
                size="subscript-lg"
                color={getAccentColor(cardBackgroundColor)}
                text={badgeLabel}
                className="origin-center rotate-180 uppercase opacity-50 [writing-mode:vertical-lr]"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContentBanner
